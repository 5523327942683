import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import Layout from '@components/Layout';

import { linkResolver } from '@utils/Prismic/linkResolver';
import { extractGraphqlData } from '@utils/extractors';

interface ITextualPages {
  data: {
    prismic: {
      allTextual_pages: {
        edges: {
          node: any;
        }[];
      };
    };
  };
}

const TextualPages: React.FC<ITextualPages> = ({ data }) => {
  const doc = extractGraphqlData(data, 'allTextual_pages');
  if (!doc) return null;
  const title = RichText.asText(doc.node.heading);
  const { page_title, page_description, canonical_tag, page_og_image } = doc.node.seo[0];

  return (
    <>
      <Layout
        title={page_title ? RichText.asText(page_title) : title}
        description={page_description && RichText.asText(page_description)}
        firstLevelPage
        seo={{
          path: `/${doc.node._meta.uid}`,
          canonical: canonical_tag && canonical_tag.url,
          image: page_og_image ? page_og_image : null,
        }}
        specLines='light'
      >
        <div className="textual">
          <div className="textual__content">
            <div className="anim-title">
              <h1>{RichText.asText(doc.node.heading)}</h1>
            </div>
            <div className="anim-block">
              {RichText.render(doc.node.body_text, linkResolver)}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query TextualPages($uid: String) {
    prismic {
      allTextual_pages(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            heading
            body_text
            seo {
              page_title
              page_description
              page_og_image
              canonical_tag {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default TextualPages;
